export const makeAlerts = () => ({
  namespaced: true,
  state: {
    alerts: [],
    alertIndex: 1,
  },
  mutations: {
    addAlert(state, alertData) {
      state.alerts.push(alertData);
      state.alertIndex++;
    },
    removeAlert(state, alertId) {
      state.alerts = state.alerts.filter((alert) => {
        return alert.id !== alertId;
      });
    },
  },
  actions: {
    add(context, alert) {
      let alertData = {
        id: context.state.alertIndex,
        title: null,
        message: null,
        button: null,
        callback: null,
        dismissible: true,
        type: "warning",
        autohide: true,
        timestamp: new Date(),
      };

      if (typeof alert === "string") {
        alertData.message = alert;
      }

      if (typeof alert === "object") {
        switch (alertData.type) {
          case "primary":
          case "secondary":
          case "success":
          case "danger":
          case "warning":
          case "info":
          case "light":
          case "dark":
            break;
          default:
            alertData.type = "warning";
            break;
        }

        alertData = Object.assign(alertData, alert);
      }

      context.commit("addAlert", alertData);

      if (alertData.autohide) {
        setTimeout(() => {
          context.commit("removeAlert", alertData.id);
        }, 5000);
      }
      return alertData.id;
    },
    remove(context, alertId) {
      if (
        context.state.alerts.filter((alert) => {
          return alert.id === alertId;
        }).length === 0
      ) {
        return false;
      }
      context.commit("removeAlert", alertId);
      return true;
    },
  },
  getters: {
    all: (state) => {
      return state.alerts;
    },
  },
});

export const alerts = makeAlerts();
